import React, { useEffect, useState } from "react";
import {
  WindBankBranchesAPI,
  WindCountriesAPI,
  WindCustomerAPI,
} from "../../../services/auth";
import { Toaster } from "react-hot-toast";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { extractFromAddress, GetMyProfileAPI } from "../../../services/invoice";
import bankSVG from "../../../images/bank-svgrepo-com.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BackSVG } from "../../../App";
import Select, { components } from "react-select";

const StatusDot = ({ isActive }) => {
  const dotStyle = {
    display: "inline-block",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: isActive === "PENDING" ? "yellow" : "green",
    marginRight: "8px",
  };

  return <span style={dotStyle}></span>;
};

const Option = (props) => (
  <components.Option {...props}>
    <div className="d-flex flex-row align-items-center">
      <img
        src={props.data.imageUrl}
        alt=""
        style={{ width: 40, height: 40, marginRight: 10 }}
      />
      <span className="font-size-14 font-weight-500">{props.data.label}</span>
    </div>
  </components.Option>
);

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    <div className="d-flex flex-row align-items-center">
      <img
        src={props.data.imageUrl}
        alt=""
        style={{ width: 40, height: 40, marginRight: 10 }}
      />
      <span className="font-size-14 font-weight-500">{props.data.label}</span>
    </div>
  </components.SingleValue>
);

const OptionBranch = (props) => (
  <components.Option {...props}>
    <div className="d-flex flex-row align-items-center justify-content-between">
      <span className="font-size-14 font-weight-500">{props.data.name}</span>
      <span className="font-size-14 font-weight-500">
        ({props.data.branchCode})
      </span>
    </div>
  </components.Option>
);

const SingleValueBranch = (props) => (
  <components.SingleValue {...props}>
    <div className="d-flex flex-row align-items-center justify-content-between">
      <span className="font-size-14 font-weight-500">{props.data.name}</span>
      <span className="font-size-14 font-weight-500">
        ({props.data.branchCode})
      </span>
    </div>
  </components.SingleValue>
);

function BankDetails() {
  const [countries, setCountries] = useState([]);
  const [banks, setBanks] = useState([]);
  const [branches, setBranches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [customerData, setCustomerData] = useState({});
  const history = useHistory();

  const SignupSchema = Yup.object().shape({
    ReceiverCountry: Yup.string().required("Country is required"),
    BankName: Yup.string().required("Bank is required"),

    BankBranchCode: Yup.string().when("branches", {
      is: (branches) => Array.isArray(branches) && branches.length > 0,
      then: (schema) => schema.required("Branch is required"),
      otherwise: (schema) => schema.optional(),
    }),

    BankAccountNumber: Yup.string()
      .required("Account number is required")
      .matches(/^[0-9]+$/, "Account number must contain only digits")
      .min(8, "Account number must be at least 8 digits"),
  });

  const getCountriesFunc = () => {
    setIsLoading(true);
    WindCountriesAPI()
      .then((res) => {
        if (res.data.data.data) {
          const options = res.data.data.data
            .map((country) => ({
              label: country.name,
              value: country.alpha3Code,
              currency: country.currencyCode,
              banks: country.banks || [],
              imageUrl: country.flagUrl,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

          if (
            options.filter((country) => country.value === userData?.country) &&
            options.filter((country) => country.value === userData?.country)
              .length > 0
          ) {
            setBanks(options[0].banks);
          }

          setIsLoading(false);
          setCountries(options);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error fetching countries:", err);
      });
  };

  const getMyProfile = () => {
    setIsLoading(true);
    GetMyProfileAPI()
      .then((res) => {
        const data = res.data.data.data;
        setUserData(data);
      })
      .catch(() => {
        setIsLoading(false);
        console.error("Error fetching user profile");
      });
  };

  useEffect(() => {
    getMyProfile();
  }, []);

  const getCustomer = () => {
    setIsLoading(true);
    WindCustomerAPI()
      .then((res) => {
        setIsLoading(false);
        const data = res.data.data.data;
        setCustomerData(data);
      })
      .catch(() => {
        setIsLoading(false);
        console.error("Error fetching user profile");
      });
  };

  useEffect(() => {
    getCustomer();
  }, []);

  useEffect(() => {
    getCountriesFunc();
  }, [userData]);

  const handleCountryChange = (selectedCountry, setFieldValue) => {
    setFieldValue("ReceiverCountry", selectedCountry.value);
    setFieldValue("PayoutCurrency", selectedCountry.currency);

    setFieldValue("BankName", "");
    setFieldValue("BankBranchCode", "");
    setBanks(selectedCountry.banks);
    setBranches([]);
  };

  const handleBankChange = (selectedBank, setFieldValue) => {
    setFieldValue("BankName", selectedBank.value);
    setFieldValue("ProcId", selectedBank.procId);
    setFieldValue("BankBranchCode", "");
    const selectedBankData = banks.find(
      (bank) => bank.name === selectedBank.value
    );
    if (selectedBankData) {
      getBankBranch(selectedBankData.id);
    }
  };

  const getBankBranch = (bankId) => {
    WindBankBranchesAPI(bankId)
      .then((res) => {
        if (res.data.data.data) {
          const branchOptions = res.data.data.data.map((branch) => ({
            label: branch.name,
            value: branch.branchCode,
            branchCode: branch.branchCode,
            name: branch.name,
          }));
          setBranches(branchOptions);
        } else {
          setBranches([]);
        }
      })
      .catch((err) => {
        console.error("Error fetching branches:", err);
        setBranches([]);
      });
  };

  const handleBranchChange = (selectedBranch, setFieldValue) => {
    setFieldValue("BankBranchCode", selectedBranch.value);
  };

  const handleSubmit = (values) => {
    history.push({
      pathname: "/invoice-beneficiary-details",
      state: values,
    });
  };
  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-column justify-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <div style={{ background: "#F7F7F7" }}>
          <div className="mt-4 mb-3 flex ml-4" style={{ width: "90%" }}>
            <div
              className="cursor-pointer"
              onClick={() => {
                history.push(`/invoice-widget`);
              }}
            >
              {BackSVG}
            </div>{" "}
            <div
              className="flex align-items-center justify-content-center"
              style={{ width: "100%" }}
            >
              <span className="font-size-18 font-weight-600">Bank Detail</span>
            </div>
          </div>
          <SimpleBar forceVisible="y" style={{ maxHeight: "88vh" }}>
            <div className="d-flex align-items-center justify-content-center">
              <img src={bankSVG} alt="bankSVG" width={80} />
            </div>
            <hr className="mt-3 mx-4" />
            <div className="d-flex justify-content-between mx-4 py-3">
              <span className="font-size-14">KYC: </span>
              <div className="font-size-14">
                <StatusDot isActive={customerData?.kycStatus} />
                {customerData?.kycStatus}
              </div>
            </div>
            {customerData?.kycStatus === "APPROVED" && (
              <div className="d-flex justify-content-between mx-4 py-3 font-size-14">
                <span>Complete your KYC: </span>
                <div
                  className="cursor-pointer font-size-14"
                  onClick={() => window.open(customerData?.kycUrl)}
                >
                  {extractFromAddress(customerData?.kycUrl, 20)}
                </div>
              </div>
            )}

            <hr className="mx-4" />
            <div
              className="mt-2"
              style={{
                minHeight: "84vh",
                position: "relative",
                marginBottom: "40px",
              }}
            >
              <Formik
                initialValues={{
                  email: userData?.email || "",
                  ReceiverCountry:
                    userData &&
                    userData.country &&
                    countries.filter(
                      (country) => country.value === userData?.country
                    ) &&
                    countries.filter(
                      (country) => country.value === userData?.country
                    ).length > 0
                      ? userData?.country
                      : "",
                  PayoutCurrency:
                    userData && userData.country
                      ? countries.find(
                          (country) => country.value === userData.country
                        ) &&
                        Object.keys(
                          countries.find(
                            (country) => country.value === userData.country
                          )
                        ).length > 0
                        ? countries.find(
                            (country) => country.value === userData.country
                          ).currency
                        : ""
                      : "",
                  BankName: "",
                  BankBranchCode: "",
                  BankAccountNumber: "",
                }}
                validationSchema={SignupSchema}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-2 flex flex-col px-4 pt-2">
                      <label
                        htmlFor="email"
                        className="font-size-14 ml-1 mb-1 font-weight-500"
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        value={values.email}
                        placeholder="Enter email"
                        onChange={handleChange}
                        name="email"
                        className={`input-field font-size-14 ${
                          errors.email && touched.email ? "error-border" : ""
                        }`}
                        disabled
                      />
                      {errors.email && touched.email && (
                        <div className="text-left mt-1 ml-2 text-danger font-size-14">
                          {errors.email}
                        </div>
                      )}
                    </div>
                    {customerData?.kycStatus === "APPROVED" && (
                      <>
                        <div className="mb-2 pt-2 flex flex-col px-4">
                          <label
                            htmlFor="country"
                            className="font-size-14 ml-1 mb-1 font-weight-500"
                          >
                            Select Country
                          </label>
                          <Select
                            components={{ Option, SingleValue }}
                            styles={{
                              menuList: (provided) => ({
                                ...provided,
                                maxHeight: "300px",
                                fontSize: "14px",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 10000,
                              }),
                            }}
                            className={`select-input font-weight-500 font-size-14 ${
                              errors.ReceiverCountry && touched.ReceiverCountry
                                ? "error-border"
                                : ""
                            }`}
                            placeholder="Select Country"
                            classNamePrefix="select-input"
                            options={countries}
                            value={countries.find(
                              (o) => o.value === values.ReceiverCountry
                            )}
                            onChange={(val) =>
                              handleCountryChange(val, setFieldValue)
                            }
                            name="ReceiverCountry"
                          />
                          {errors.ReceiverCountry &&
                            touched.ReceiverCountry && (
                              <div className="text-left mt-1 ml-2 text-danger font-size-14">
                                {errors.ReceiverCountry}
                              </div>
                            )}
                        </div>

                        <div className="mb-2 flex flex-col px-4 pt-2">
                          <label
                            htmlFor="email"
                            className="font-size-14 ml-1 mb-1 font-weight-500"
                          >
                            Currency
                          </label>
                          <input
                            type="text"
                            value={values.PayoutCurrency}
                            placeholder="Enter currency"
                            onChange={handleChange}
                            name="PayoutCurrency"
                            className={`input-field font-size-14 ${
                              errors.PayoutCurrency && touched.PayoutCurrency
                                ? "error-border"
                                : ""
                            }`}
                            disabled
                          />
                          {errors.PayoutCurrency && touched.PayoutCurrency && (
                            <div className="text-left mt-1 ml-2 text-danger font-size-14">
                              {errors.PayoutCurrency}
                            </div>
                          )}
                        </div>

                        {banks && banks.length > 0 && (
                          <div className="mb-2 pt-2 flex flex-col px-4">
                            <label
                              htmlFor="BankName"
                              className="font-size-14 ml-1 mb-1 font-weight-500"
                            >
                              Select Bank
                            </label>
                            <Select
                              styles={{
                                menuList: (provided) => ({
                                  ...provided,
                                  maxHeight: "300px",
                                  fontSize: "14px",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 10000,
                                }),
                              }}
                              className={`select-input font-weight-500 font-size-14 ${
                                errors.BankName && touched.BankName
                                  ? "error-border"
                                  : ""
                              }`}
                              placeholder="Select Bank"
                              classNamePrefix="select-input"
                              options={banks.map((bank) => ({
                                label: bank.name,
                                value: bank.name,
                                procId: bank.procId,
                              }))}
                              value={
                                values.BankName
                                  ? banks.find(
                                      (o) => o.value === values.BankName
                                    )
                                  : null
                              }
                              onChange={(val) =>
                                handleBankChange(val, setFieldValue)
                              }
                              name="BankName"
                            />

                            {errors.BankName && touched.BankName && (
                              <div className="text-left mt-1 ml-2 text-danger font-size-14">
                                {errors.BankName}
                              </div>
                            )}
                          </div>
                        )}

                        {branches && branches.length > 0 && values.BankName && (
                          <div className="mb-2 pt-2 flex flex-col px-4">
                            <label
                              htmlFor="BankBranchCode"
                              className="font-size-14 ml-1 mb-1 font-weight-500"
                            >
                              Select Branch
                            </label>
                            <Select
                              components={{
                                Option: OptionBranch,
                                SingleValue: SingleValueBranch,
                              }}
                              styles={{
                                menuList: (provided) => ({
                                  ...provided,
                                  maxHeight: "300px",
                                  fontSize: "14px",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 10000,
                                }),
                              }}
                              className={`select-input font-weight-500 font-size-14 ${
                                errors.BankBranchCode && touched.BankBranchCode
                                  ? "error-border"
                                  : ""
                              }`}
                              placeholder="Select Branch"
                              classNamePrefix="select-input"
                              options={branches}
                              value={branches.find(
                                (o) => o.value === values.BankBranchCode
                              )}
                              onChange={(val) =>
                                handleBranchChange(val, setFieldValue)
                              }
                              name="BankBranchCode"
                            />
                            {errors.BankBranchCode &&
                              touched.BankBranchCode && (
                                <div className="text-left mt-1 ml-2 text-danger font-size-14">
                                  Branch is required
                                </div>
                              )}
                          </div>
                        )}

                        {values.BankName && (
                          <div className="mb-2 flex flex-col mb-4 px-4 pt-2">
                            <label
                              htmlFor="email"
                              className="font-size-14 ml-1 mb-1 font-weight-500"
                            >
                              Bank Account No.
                            </label>
                            <input
                              type="text"
                              value={values.BankAccountNumber}
                              placeholder="Enter Account Number"
                              onChange={handleChange}
                              name="BankAccountNumber"
                              className={`input-field font-size-14 ${
                                errors.BankAccountNumber &&
                                touched.BankAccountNumber
                                  ? "error-border"
                                  : ""
                              }`}
                            />
                            {errors.BankAccountNumber &&
                              touched.BankAccountNumber && (
                                <div className="text-left mt-1 ml-2 text-danger font-size-14">
                                  {errors.BankAccountNumber}
                                </div>
                              )}{" "}
                          </div>
                        )}
                        <div
                          className="flex justify-center p-4"
                          style={{
                            width: "100%",
                            position: "fixed",
                            bottom: "5px",
                          }}
                        >
                          <button
                            id="login-button"
                            type="submit"
                            className="button-style"
                          >
                            <div className="font-size-14">Continue</div>
                          </button>
                        </div>
                      </>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </SimpleBar>
        </div>
      )}
      <Toaster position="bottom-center" reverseOrder={false} />
    </React.Fragment>
  );
}

export default BankDetails;
