import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { GetInvoiceDetailsAPI } from "../../services/invoice";
import QRCode from "react-qr-code";
import toast, { Toaster } from "react-hot-toast";
import { BackSVG } from "../../App";
import { transfer } from "thirdweb/extensions/erc20";
import { createWallet, injectedProvider } from "thirdweb/wallets";
import {
  TransactionButton,
  useActiveAccount,
  useConnect,
} from "thirdweb/react";
import { client } from "../Invoice/client";
import { polygon } from "thirdweb/chains";
import { getContract } from "thirdweb";

function CryptoPayment() {
  const { invoice_code } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [invoiceData, setInvoiceData] = useState(null);
  const history = useHistory();
  const { connect, isConnecting, error } = useConnect();
  const account = useActiveAccount();

  const getInvoiceDetails = () => {
    setIsLoading(true);
    GetInvoiceDetailsAPI(invoice_code)
      .then((res) => {
        setIsLoading(false);
        setInvoiceData({
          ...res.data.data.data,
          ...res.data.data.industryType,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMsg = err.data?.error?.message;
        setErrorMessage(errorMsg);
      });
  };

  useEffect(() => {
    if (invoice_code) {
      getInvoiceDetails();
    } else {
      setErrorMessage("Invoice code is required");
    }
  }, [invoice_code]);

  const walletAddress = invoiceData?.wallet;
  const amountInUSDC =
    invoiceData?.pay_fee === "you_pay"
      ? Number(invoiceData?.amount).toFixed(6)
      : Number(invoiceData?.final_amount).toFixed(6);

  const amountInWei = amountInUSDC
    ? (Number(amountInUSDC).toFixed(6) * 1e6).toString()
    : "0";

  const qrValue = `ethereum:0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359@137/transfer?address=${walletAddress}&uint256=${amountInWei}`;

  const usdcContract = getContract({
    address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    chain: polygon,
    client: client,
  });


  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-column justify-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <>
          {errorMessage && <span>{errorMessage}</span>}
          {invoiceData && Object.keys(invoiceData).length > 0 && (
            <>
              <div style={{ background: "#F7F7F7" }}>
                <div className="mt-4 mb-3 flex ml-4" style={{ width: "90%" }}>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    {BackSVG}
                  </div>
                  <div
                    className="flex align-items-center justify-content-center"
                    style={{ width: "100%" }}
                  >
                    <span className="font-size-18 font-weight-600">Crypto</span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center flex-col mt-3">
                  <span className="font-size-16 text-black font-weight-600">
                    Total Purchase
                  </span>
                  <span className="font-size-16 font-weight-400">
                    $ {amountInUSDC}
                  </span>
                  <span className="font-size-34 text-black font-weight-600 mt-4">
                    {amountInUSDC} USDC
                  </span>
                  <div className="mt-4 d-flex justify-content-center flex-col font-weight-600">
                    <p className="font-size-16 mt-2">Scan QR for payment</p>
                  </div>
                  <div className="mt-4 d-flex justify-content-center flex-col">
                    <QRCode value={qrValue} size={150} />
                  </div>
                </div>
              </div>

              <div
                className="flex justify-center p-4 button-div"
                style={{
                  position: "fixed",
                  bottom: "65px",
                }}
              >
                {!account ? (
                  <button
                    style={{
                      background: "#000",
                      color: "white",
                      minWidth: "100%",
                      height: "60px",
                      borderRadius: `33px`,
                    }}
                    onClick={() =>
                      connect(async () => {
                        const wallet = createWallet("io.metamask");
                        if (injectedProvider("io.metamask")) {
                          await wallet.connect({ client });
                        } else {
                          await wallet.connect({
                            chain: polygon,
                            client,
                            walletConnect: { showQrModal: true },
                          });
                        }
                        return wallet;
                      })
                    }
                  >
                    <div className="flex align-items-center font-size-12 justify-content-center px-3">
                      {isConnecting ? (
                        <span className="font-size-14 font-weight-bold px-3">
                          Connecting...
                        </span>
                      ) : (
                        <div className="d-flex align-items-center px-3">
                          <span className="font-size-16 px-2">
                            Connect Wallet
                          </span>
                        </div>
                      )}
                    </div>
                  </button>
                ) : (
                  <TransactionButton
                    style={{
                      background: "#000",
                      color: "white",
                      minWidth: "100%",
                      height: "50px",
                      borderRadius: `33px`,
                    }}
                    transaction={() => {
                      if (!account) throw new Error("No active account");
                      return transfer({
                        contract: usdcContract,
                        amount: amountInUSDC,
                        to: walletAddress,
                      });
                    }}
                    onTransactionSent={(res) => {
                      history.push(`/crypto-paid/${invoice_code}`);
                    }}
                    onTransactionConfirmed={(res) => {
                      history.push(`/crypto-paid/${invoice_code}`);
                    }}
                    onError={(e) => {
                      console.log({ e });

                      toast.error("Transfer amount exceeds balance");
                    }}
                  >
                    Pay Now
                  </TransactionButton>
                )}
              </div>
              <div
                className="flex justify-center p-4 button-div"
                style={{
                  position: "fixed",
                  bottom: "5px",
                }}
                onClick={() => history.push(`/crypto-paid/${invoice_code}`)}
              >
                <button
                  id="login-button"
                  type="submit"
                  className="button-style"
                  style={{
                    background: "white",
                    color: "black",
                    border: "1px solid",
                  }}
                >
                  <div className="font-size-14">
                    I have sent {amountInUSDC} USDT
                  </div>
                </button>
              </div>
            </>
          )}
        </>
      )}
      <Toaster position="bottom-center" reverseOrder={false} />
    </React.Fragment>
  );
}

export default CryptoPayment;
