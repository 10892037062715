import React from "react";

function Complete({ setStep }) {
  return (
    <div className="search-box glass-effect py-4">
      <span className="font-size-22">Thank You for Your Interest! 🌟</span>
      <span>
        Our team will reach out to you shortly to discuss the next steps.
      </span>
      <button
        type="button"
        className="search-button"
        onClick={() => setStep(0)}
      >
        Resubmit
      </button>
    </div>
  );
}

export default Complete;
