import React, { useEffect, useState } from "react";
import {
  GetInvoiceDetailsAPI,
  IsBankInvoicePaidAPI,
} from "../../services/invoice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function BankInvoicePaid() {
  const { invoice_code } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const invoicePaidCheck = () => {
    IsBankInvoicePaidAPI(invoice_code)
      .then((res) => {
        getInvoiceDetails();
      })
      .catch((err) => {});
  };

  const getInvoiceDetails = () => {
    setIsLoading(true);
    GetInvoiceDetailsAPI(invoice_code)
      .then((res) => {
        setIsLoading(false);
        setInvoiceDetail(res.data.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMsg = err.data?.error?.message;
        setErrorMessage(errorMsg);
      });
  };

  useEffect(() => {
    if (invoice_code) {
      getInvoiceDetails();
    } else {
      setErrorMessage("Invoice code is required");
    }
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-column justify-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <>
          {errorMessage && errorMessage !== "" ? (
            <div
              className="flex flex-column justify-center"
              style={{ height: "100vh", alignItems: "center" }}
            >
              <span style={{ color: "#ff4560", fontSize: "20px" }}>
                {errorMessage}
              </span>
            </div>
          ) : (
            <>
              <div style={{ background: "#F7F7F7" }}>
                <div className="mt-4 mb-3 flex ml-4" style={{ width: "90%" }}>
                  <div
                    className="flex align-items-center justify-content-center"
                    style={{ width: "100%" }}
                  >
                    <span className="font-size-18 font-weight-600">
                      Invoice
                    </span>
                  </div>
                </div>
                <div
                  className="mx-4 p-3 d-flex justify-content-between align-items-center"
                  style={{
                    background: "#EDEFF3",
                    borderRadius: "16px",
                    marginTop: "5%",
                    height: "55px",
                  }}
                >
                  <span className="font-size-14 font-weight-500 text-black">
                    Invoice no. {invoiceDetail.short_code}
                  </span>
                  <div className="d-flex flex-row align-items-center">
                    <div className="button-group">
                      {!invoiceDetail.is_paid ? (
                        <button
                          className={`font-size-14 font-weight-500`}
                          style={{
                            background: "#FFC694",
                            border: "0px",
                            color: "#000",
                          }}
                        >
                          Pending
                        </button>
                      ) : (
                        <span
                          className="ml-3 px-2 py-1 text-xs text-white rounded-full"
                          style={{ background: "#BEFF7D" }}
                        >
                          Complete
                        </span>
                      )}
                    </div>
                    {!invoiceDetail.is_paid && (
                      <span
                        className="font-size-12 cursor-pointer ml-2"
                        onClick={invoicePaidCheck}
                      >
                        Check Status
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className="mx-4 p-3"
                  style={{
                    background: "#EDEFF3",
                    borderRadius: "18px",
                    marginTop: "5%",
                  }}
                >
                  <div>
                    <label
                      htmlFor="title"
                      className="font-size-14 label-color px-2"
                    >
                      Item
                    </label>
                  </div>
                  <div className="mt-1">
                    {invoiceDetail &&
                    invoiceDetail.items &&
                    invoiceDetail.items.length > 0 ? (
                      invoiceDetail.items.map((item, index) => (
                        <div key={index}>
                          <div className="d-flex flex-row align-items-center justify-content-between">
                            <div className="d-flex flex-row align-items-center">
                              <div className="d-flex flex-col">
                                <span className="font-size-16 font-weight-500 mx-2">
                                  {item.itemTitle}
                                </span>
                                <span
                                  className="font-size-12 mx-2"
                                  style={{ color: "#6F6F6F" }}
                                >
                                  QTY {item.quantity} X $ {item.price}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center mt-2">
                              <span className="mr-2 font-size-14">
                                ${Number(item.quantity) * Number(item.price)}
                              </span>
                            </div>
                          </div>
                          {invoiceDetail.items.length !== index + 1 ? (
                            <hr className="my-3" />
                          ) : (
                            <hr
                              className="mb-3"
                              style={{ border: "transparent" }}
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="d-flex flex-row align-items-center">
                          <div className="d-flex flex-col">
                            <span className="font-size-16 font-weight-500 mx-2">
                              {invoiceDetail.title}
                            </span>
                            <span
                              className="font-size-12 mx-2"
                              style={{ color: "#6F6F6F" }}
                            >
                              QTY {1} X $ {invoiceDetail.amount}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                          <span className="mr-2 font-size-14">
                            ${Number(invoiceDetail.amount)}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="mx-4 my-3"
                  style={{
                    background: "#EDEFF3",
                    borderRadius: "18px",
                  }}
                >
                  <div className="pt-3 px-3 d-flex flex-row align-items-center justify-content-between">
                    <label
                      htmlFor="title"
                      className="font-size-14 label-color font-weight-500 mx-2"
                    >
                      Sub total
                    </label>
                    <label
                      htmlFor="title"
                      className="font-size-14 label-color font-weight-500 mx-2"
                    >
                      $ {invoiceDetail.amount}
                    </label>
                  </div>

                  <div className="pt-3 px-3 d-flex flex-row align-items-center justify-content-between">
                    <label
                      htmlFor="title"
                      className="font-size-14 label-color font-weight-500 mx-2"
                    >
                      Discount
                    </label>
                    <label
                      htmlFor="title"
                      className="font-size-14 label-color font-weight-500 mx-2"
                    >
                      0 %
                    </label>
                  </div>

                  <div className="pt-3 px-3 d-flex flex-row align-items-center justify-content-between">
                    <label
                      htmlFor="title"
                      className="font-size-14 label-color font-weight-500 mx-2"
                    >
                      Tax
                    </label>
                    <label
                      htmlFor="title"
                      className="font-size-14 label-color font-weight-500 mx-2"
                    >
                      0 %
                    </label>
                  </div>

                  <div
                    className="mt-3"
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      borderBottomLeftRadius: "18px",
                      borderBottomRightRadius: "18px",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <span className="px-3 font-size-16">Total</span>
                      <span className="px-3 font-size-16">
                        $ {invoiceDetail.amount}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
}

export default BankInvoicePaid;
