import copy from "copy-to-clipboard";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { RiFileCopyLine } from "react-icons/ri";
// import { extractFromAddress } from "../../../services/invoice";

function InvoiceTitle({
  values,
  handleChange,
  errors,
  touched,
  setFieldValue,
  beneficiaryData,
  userData,
}) {
  return (
    <div>
      <span className="text-left font-size-32 page-title mt-4 pt-4 px-4">
        New Payment Link
      </span>
      <div className="mb-1 flex flex-col px-4 pt-4">
        <label htmlFor="title" className="font-size-14 label-color ml-1 mb-1">
          Invoice Title
        </label>
        <input
          type="text"
          value={values.title}
          placeholder="Enter invoice title"
          onChange={handleChange}
          name="title"
          className={`input-field font-size-14 ${
            errors.title && touched.title ? "error-border" : ""
          }`}
        />
        {errors.title && touched.title && (
          <div className="text-left mt-1 ml-1 text-danger font-size-14">
            {errors.title}
          </div>
        )}
      </div>
      <div className="mb-3 flex flex-col px-4 pt-1">
        <label className="font-size-14 label-color ml-1 mb-1">
          Who Pays Fee?
        </label>
        <div className="flex flex-row">
          <div className="flex align-items-center ml-1">
            <input
              type="radio"
              name="pay_fee"
              className="mt-2 mr-2"
              value={"you_pay"}
              checked={values.pay_fee === "you_pay"}
              onChange={() => {
                setFieldValue("pay_fee", "you_pay");
              }}
            />{" "}
            <label className="font-size-14 mt-2">You will pay fee</label>
          </div>
          <div className="flex align-items-center ml-4">
            <input
              type="radio"
              name="pay_fee"
              className="mt-2 mr-2"
              value={"customer_pay"}
              checked={values.pay_fee === "customer_pay"}
              onChange={() => {
                setFieldValue("pay_fee", "customer_pay");
              }}
            />{" "}
            <label className="font-size-14 mt-2">Customer will pay fee</label>
          </div>
        </div>
        {errors.pay_fee && touched.pay_fee && (
          <div className="text-left mt-1 ml-1 text-danger font-size-14">
            {errors.pay_fee}
          </div>
        )}
      </div>
      {beneficiaryData && Object.keys(beneficiaryData).length > 0 && (
        <>
          <div className="mb-3 flex flex-col px-4 pt-1">
            <label htmlFor="title" className="font-size-16 ml-1 mb-1">
              Receive In
            </label>
            <div className="flex flex-row">
              <div className="flex align-items-center ml-1">
                <input
                  type="radio"
                  name="receive_in"
                  className="mt-2 mr-2 cursor-pointer"
                  value={"crypto"}
                  checked={values.receive_in === "crypto"}
                  onChange={() => {
                    setFieldValue("receive_in", "crypto");
                  }}
                />{" "}
                <label className="font-size-14 mt-2">Crypto</label>
              </div>
              <div className="flex align-items-center ml-4">
                <input
                  type="radio"
                  name="receive_in"
                  className="mt-2 mr-2 cursor-pointer"
                  value={"fiat"}
                  checked={values.receive_in === "fiat"}
                  onChange={() => {
                    setFieldValue("receive_in", "fiat");
                  }}
                />{" "}
                <label className="font-size-14 mt-2">Fiat</label>
              </div>
            </div>
            {errors.receive_in && touched.receive_in && (
              <div className="text-left mt-1 ml-2 text-danger font-size-14">
                {errors.receive_in}
              </div>
            )}
          </div>
          <div className="mx-4">
            {values.receive_in === "crypto" ? (
              <div className="">
                <span className="font-size-14 font-weight-500">
                  Deposit Wallet
                </span>
                <div className="mb-1 flex flex-row items-center">
                  <input
                    type="text"
                    value={userData.invoice_deposit_wallet}
                    placeholder="Enter email address"
                    name="email"
                    className={`input-field font-size-14 flex-1`}
                    style={{ paddingRight: "36px" }}
                    disabled
                  />
                  <div
                    onClick={() => {
                      copy(userData.invoice_deposit_wallet);
                      toast.success("Wallet address copied successfully", {
                        style: { fontSize: "12px" },
                      });
                    }}
                    style={{
                      position: "absolute",
                      right: "25px",
                      fontSize: "24px",
                    }}
                  >
                    <RiFileCopyLine className="ml-2 cursor-pointer" />
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="p-4 bg-white/90 mt-3 shadow-lg rounded-lg hover:shadow-2xl transition transform hover:-translate-y-1 duration-300"
                style={{ background: "#F9F9F9" }}
              >
                <span className="font-size-14 font-weight-500">
                  Bank Account Details
                </span>
                <div className="flex justify-between items-center mb-3">
                  {beneficiaryData &&
                    beneficiaryData.country &&
                    beneficiaryData.country.length > 0 &&
                    beneficiaryData.country[0].flagUrl && (
                      <img
                        src={beneficiaryData.country[0].flagUrl}
                        alt="Country Flag"
                        className="w-8 h-8 rounded-full"
                      />
                    )}
                </div>

                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div>
                    <span className="font-semibold text-gray-700">
                      Full Name:
                    </span>
                    <span className="ml-2 text-gray-600">
                      {beneficiaryData.beneficiary.ReceiverFirstName}{" "}
                      {beneficiaryData.beneficiary.ReceiverLastName}
                    </span>
                  </div>
                  <div>
                    <span className="font-semibold text-gray-700">
                      Ac. No.:
                    </span>
                    <span className="ml-2 text-gray-600">
                      {beneficiaryData.BankAccountNumber.slice(0, 4)} XXXX{" "}
                      {beneficiaryData.BankAccountNumber.slice(-4)}
                    </span>
                  </div>
                </div>
                <div className="text-sm mt-2">
                  <span className="font-semibold text-gray-700">
                    Bank Name:
                  </span>
                  <span className="ml-2 text-gray-600">
                    {beneficiaryData.BankName}
                  </span>
                </div>
                <div className="text-sm mt-2">
                  <span className="font-semibold text-gray-700">Currency:</span>
                  <span className="ml-2 text-gray-600">
                    {beneficiaryData.PayoutCurrency}
                  </span>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      <div
        className="flex justify-center p-4"
        style={{
          position: "fixed",
          bottom: "5px",
          width: "100%",
        }}
      >
        <button type="submit" className="button-style font-size-18">
          Next
        </button>
      </div>
      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
}

export default InvoiceTitle;
