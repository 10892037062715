import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "./Property.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { FaUser, FaEnvelope } from "react-icons/fa";

const inputStyle = {
  width: "100%",
  backgroundColor: "transparent",
  paddingLeft: "50px",
  border: "2px solid white",
  fontSize: "14px",
  height: "55px",
  color: "white",
};

const buttonStyle = {
  background: "none",
  paddingLeft: "2px",
  padding: "0px",
};

const dropdownStyle = {
  fontSize: "16px",
  fontWeight: "500",
  color: "black",
  backgroundColor: "#FFFFFF",
  maxHeight: "300px",
  width: "250px",
  overflowY: "auto",
};

const searchStyle = {
  fontSize: "14px",
  padding: "10px",
  marginBottom: "8px",
};

function ContactInfo({
  handleSubmit,
  setStep,
  defaultValues,
  setIpDetails,
  shakeIt,
}) {
  const [country, setCountry] = useState("us");
  const contactInfoSubmit = (values) => {
    const data = {
      email: values.email,
      fullName: values.fullName,
      phoneNumber: values.phoneNumber,
    };
    handleSubmit(data);
    setStep(1);
  };

  useEffect(() => {
    const detectCountry = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        setIpDetails(data);
        if (data && data.country_code) {
          setCountry(data.country_code.toLowerCase());
        }
      } catch (error) {
        console.error("Error detecting country:", error);
      }
    };

    detectCountry();
  }, []);

  return (
    <Formik
      initialValues={{
        fullName: defaultValues?.fullName || "",
        email: defaultValues.email ? defaultValues.email : "",
        phoneNumber: defaultValues.phoneNumber ? defaultValues.phoneNumber : "",
      }}
      validationSchema={Yup.object({
        fullName: Yup.string().required("Full Name is required"),
        email: Yup.string()
          .email("Invalid email address")
          .required("Email Address is required"),
        phoneNumber: Yup.string()
          .matches(/^\+?[1-9]\d{1,14}$/, "Invalid phone number format")
          .min(7, "Phone number is required")
          .required("Phone Number is required"),
      })}
      enableReinitialize
      onSubmit={contactInfoSubmit}
    >
      {({
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        touched,
        errors,
        handleSubmit,
      }) => (
        <Form
          className={`search-box glass-effect py-4 ${
            shakeIt && "tilt-shaking"
          }`}
          onSubmit={handleSubmit}
        >
          <span className="font-size-18 font-weight-500">
            Contact Information
          </span>
          <div className="search-field input-icon-wrapper">
            <FaUser className="input-icon" />
            <input
              id="fullName"
              name="fullName"
              className="input-field-pr"
              type="text"
              placeholder="Full Name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fullName}
              autoComplete="off"
            />
          </div>
          {touched.fullName && errors.fullName ? (
            <div className="error-message">{errors.fullName}</div>
          ) : null}
          <div className="search-field input-icon-wrapper">
            <FaEnvelope className="input-icon" />
            <input
              id="email"
              name="email"
              className="input-field-pr"
              type="email"
              placeholder="Email Address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              autoComplete="off"
            />
          </div>
          {touched.email && errors.email ? (
            <div className="error-message">{errors.email}</div>
          ) : null}

          <div className="search-field">
            <PhoneInput
              country={country}
              value={values.phoneNumber}
              inputStyle={{
                ...inputStyle,
                border: "2px solid white",
              }}
              onChange={(val) => {
                setFieldValue("phoneNumber", val || "");
              }}
              buttonStyle={buttonStyle}
              dropdownClass="font-size-14"
              dropdownStyle={dropdownStyle}
              specialLabel=""
              placeholder="WhatsApp or Line communication"
              searchStyle={searchStyle}
            />
          </div>

          {touched.phoneNumber && errors.phoneNumber ? (
            <div className="error-message">{errors.phoneNumber}</div>
          ) : null}
          <button
            type="submit"
            className="search-button"
            onSubmit={handleSubmit}
          >
            Continue
          </button>
        </Form>
      )}
    </Formik>
  );
}

export default ContactInfo;
