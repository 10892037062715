import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { BackSVG, SentSVG } from "../../App";
import { GetInvoicesAPI } from "../../services/invoice";
import { Link } from "react-router-dom";

function Invoices() {
  const history = useHistory();
  const [filter, setFilter] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [invoicesList, setInvoicesList] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);

  const getInvoicesList = () => {
    setIsLoading(true);
    GetInvoicesAPI()
      .then((res) => {
        const data = res.data.data.data;
        setInvoicesList(data);
        applyFilter(filter, data);
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  const applyFilter = (filterType, list) => {
    let filteredData;
    if (filterType === "sent") {
      filteredData = list.filter((invoice) => !invoice.is_paid);
    } else if (filterType === "complete") {
      filteredData = list.filter((invoice) => invoice.is_paid);
    } else {
      filteredData = list;
    }
    setFilteredInvoices(filteredData);
  };

  useEffect(() => {
    getInvoicesList();
  }, []);

  useEffect(() => {
    applyFilter(filter, invoicesList);
  }, [filter, invoicesList]);

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-col justify-center align-items-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <>
          <div className="p-4 d-flex flex-row align-items-center justify-content-between">
            <div
              className="cursor-pointer"
              onClick={() => history.push("/settings")}
            >
              {BackSVG}
            </div>
            <span className="d-flex justify-content-center align-items-center mx-auto font-size-18 font-weight-600">
              Invoices
            </span>
          </div>
          <div className="button-group px-4">
            <button
              className={`${filter === "all" ? "is-active" : ""} font-size-12`}
              onClick={() => setFilter("all")}
            >
              All
            </button>
            <button
              className={`${filter === "sent" ? "is-active" : ""} font-size-12`}
              onClick={() => setFilter("sent")}
            >
              Sent
            </button>
            <button
              className={`${
                filter === "complete" ? "is-active" : ""
              } font-size-12`}
              onClick={() => setFilter("complete")}
            >
              Complete
            </button>
          </div>
          <SimpleBar
            forceVisible="y"
            style={{ maxHeight: "85vh" }}
            className="p-4"
          >
            {filteredInvoices && filteredInvoices.length > 0 ? (
              <div>
                {filteredInvoices.map((invoice) => (
                  <Link
                    to={`/invoice-detail?invoice_code=${invoice.short_code}`}
                    key={invoice.short_code}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-col">
                        <span className="font-size-14 font-weight-500">
                          {invoice.title}
                        </span>
                        <div className="d-flex flex-row mt-2">
                          <span className="font-size-12 font-weight-300">
                            $ {invoice.amount}
                          </span>
                          <span className="mt-1 ml-2">{SentSVG}</span>
                        </div>
                      </div>
                      <div className="button-group">
                        {invoice.is_paid ? (
                          <button
                            className={`font-size-12 font-weight-300`}
                            style={{ background: "#BEFF7D", border: "0px" }}
                          >
                            Complete
                          </button>
                        ) : (
                          <button
                            className={`font-size-12 font-weight-300`}
                            style={{ background: "#FFC694", border: "0px" }}
                          >
                            Pending
                          </button>
                        )}
                      </div>
                    </div>
                    <hr className="my-2" />
                  </Link>
                ))}
              </div>
            ) : (
              <div
                className="d-flex font-size-18 font-weight-500 align-items-center justify-content-center"
                style={{ height: "80vh", width: "100%" }}
              >
                No invoice found
              </div>
            )}
          </SimpleBar>
        </>
      )}
    </React.Fragment>
  );
}

export default Invoices;
