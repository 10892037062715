import React, { useEffect, useRef, useState } from "react";
import "./Property.css";
import skyscraper from "../../images/9395415b505c7d6c22c6cca730874224.jpg";
import "react-phone-input-2/lib/bootstrap.css";
import ContactInfo from "./ContactInfo";
import Communication from "./Communication";
import InvestmentPreferences from "./InvestmentPreferences";
import PaymentMethod from "./PaymentMethod";
import Consent from "./Consent";
import { AddPropertyAPI, GetPropertyAPI } from "../../services/property";
import Complete from "./Complete";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

const cryptoList = ["₿", "Ξ", "⟠", "₮"];
const fiatSymbols = ["¥", "£", "$", "€"];

function SlotMachine({ items }) {
  const containerRef = useRef(null);
  const [topPosition, setTopPosition] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * items.length);
      setTopPosition(-randomIndex * 40);
    }, 2000);

    return () => clearInterval(interval);
  }, [items]);

  return (
    <div className="slottt-machine-recipe__mask ml-2">
      <div
        className="slottt-machine-recipe__items_container"
        ref={containerRef}
        style={{ top: `${topPosition}px`, transition: "top 0.5s ease-in-out" }}
      >
        {items.map((item, index) => (
          <div key={index} className="slottt-machine-recipe__item">
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}

function Property() {
  const [step, setStep] = useState(0);
  const [defaultValues, setDefaultValues] = useState({});
  const [email, setEmail] = useState(null);
  const [ipDetails, setIpDetails] = useState(null);
  const [shakeIt, setShakeIt] = useState(false);
  const contactSectionRef = useRef(null);

  useEffect(() => {
    document.body.style.background = "black";
    return () => {
      document.body.style.background = "black";
    };
  }, []);

  const getFormDetails = (email) => {
    GetPropertyAPI(email)
      .then((res) => {
        setDefaultValues(res.data.data.data);
      })
      .catch(() => {});
  };

  const handleSubmit = (values) => {
    AddPropertyAPI(values)
      .then((res) => {
        getFormDetails(values.email);
        setEmail(values.email);
      })
      .catch(() => {});
  };

  const handleContactClick = () => {
    setShakeIt(true);
    setTimeout(() => setShakeIt(false), 1000);

    if (window.innerWidth <= 768) {
      contactSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <SimpleBar forceVisible="y" style={{ maxHeight: "100vh" }}>
      <div className="property-wrapper bg-black">
        <div className="left-section">
          <div className="logo">
            <h1>1buy.io</h1>
          </div>
          <div
            className="main-text line-by-line-animation"
            style={{ paddingRight: "5%" }}
          >
            <h1 className="line-animate">
              Invest in{" "}
              <span style={{ color: "gold" }}>Exclusive UAE Properties</span>{" "}
              with
              <SlotMachine items={cryptoList} /> and
              <SlotMachine items={fiatSymbols} />
            </h1>
            <h2 className="line-animate">
              Discover{" "}
              <span style={{ color: "gold" }}>
                luxury villas and residences
              </span>{" "}
              available for investment in the UAE.
            </h2>
          </div>
        </div>
        <div className="right-section image-slide-up" ref={contactSectionRef}>
          <img src={skyscraper} alt="skyscraper" className="property-image" />
          {step === 0 && (
            <ContactInfo
              step={step}
              setStep={setStep}
              handleSubmit={handleSubmit}
              defaultValues={defaultValues}
              setIpDetails={setIpDetails}
              shakeIt={shakeIt}
            />
          )}
          {step === 1 && (
            <Communication
              step={step}
              setStep={setStep}
              handleSubmit={handleSubmit}
              email={email}
              defaultValues={defaultValues}
              shakeIt={shakeIt}
            />
          )}
          {step === 2 && (
            <InvestmentPreferences
              step={step}
              setStep={setStep}
              handleSubmit={handleSubmit}
              email={email}
              defaultValues={defaultValues}
              shakeIt={shakeIt}
            />
          )}
          {step === 3 && (
            <PaymentMethod
              step={step}
              setStep={setStep}
              handleSubmit={handleSubmit}
              email={email}
              defaultValues={defaultValues}
              ipDetails={ipDetails}
              shakeIt={shakeIt}
            />
          )}
          {step === 4 && (
            <Consent
              step={step}
              setStep={setStep}
              handleSubmit={handleSubmit}
              email={email}
              defaultValues={defaultValues}
              shakeIt={shakeIt}
            />
          )}

          {step === 5 && <Complete setStep={setStep} />}
        </div>
      </div>
      <button
        className="contact-button-fixed"
        onClick={() => {
          handleContactClick();
        }}
      >
        <span className="phone-icon">📞</span>
        Contact Us Now
      </button>
    </SimpleBar>
  );
}

export default Property;
