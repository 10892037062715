export const DevURL = `${process.env.REACT_APP_BACK_END_HOST}/api/`;
// export const DevURL = `http://localhost:4008/api/`;

export const BUSINESS = "business/";

// ? Authentication URL
export const INVOICE_LOGIN_URL = BUSINESS + "invoice_login";
export const INVOICE_VERIFICATION_URL = BUSINESS + "invoice_verification";
export const PROPERTY = "property";
export const LOGIN_URL = BUSINESS + "login";
export const REGISTER_URL = BUSINESS + "register";
export const VERIFICATION = BUSINESS + "verification";
export const RESEND_CODE = BUSINESS + "resend_code";
export const FORGOT_PASSWORD = BUSINESS + "forgot_password";
export const CHECK_OTP = BUSINESS + "check_otp";
export const CHECK_EMAIL = BUSINESS + "check_email";
export const RESET_PASSWORD = BUSINESS + "change_password";

export const ME = BUSINESS + "me";

export const INVOICE_DETAILS = BUSINESS + "smart_contract/invoice_details";
export const DEPLOY_INVOICE = BUSINESS + "smart_contract/deploy_invoice";
export const INVOICE = BUSINESS + "smart_contract/invoice";
export const IS_INVOICE_PAID = BUSINESS + "smart_contract/is_invoice_paid";
export const IS_BANK_INVOICE_PAID =
  BUSINESS + "smart_contract/is_bank_invoice_paid";
export const TRANSACTIONS = BUSINESS + "smart_contract/transactions";
export const INVOICE_DEPOSIT_WALLET = BUSINESS + "invoice_deposit_wallet";
export const FLAP_SC_INPUT = "business/product/flap_details";
export const INDUSTRIES = BUSINESS + "industries";
export const COUNTRIES = BUSINESS + "countries";

export const WIND_COUNTRIES = BUSINESS + "wind/countries";
export const WIND_BANK_BRANCHES = BUSINESS + "wind/bank_branches";
export const WIND_BANK_DETAILS = BUSINESS + "wind/bank_details";
export const GET_CUSTOMER = BUSINESS + "wind/get_customer_details";
export const REQUIRE_FIELDS = BUSINESS + "wind/required_fields";
export const GET_BENEFICIARY = BUSINESS + "wind/beneficiary_details";
export const ALL_BENEFICIARY = BUSINESS + "wind/all_beneficiary";

export const URL = DevURL;
