import api from "../api/userApi";
import * as url from "../api/userURL";

export const userForgotPassword = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.FORGOT_PASSWORD, {
        ...payload,
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const userResetPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.RESET_PASSWORD, {
        ...data,
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const CheckOTPAPI = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.CHECK_OTP, {
        ...payload,
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const CheckEmailAPI = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.CHECK_EMAIL, {
        ...payload,
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const userAuthRegister = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.REGISTER_URL, {
        ...data,
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const authResendCode = (paydata) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.RESEND_CODE, {
        ...paydata,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AuthVerifyEmail = (paydata) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.VERIFICATION, {
        ...paydata,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const FlapInputDataAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postDirect(
        data.is_dev === "yes"
          ? `${process.env.REACT_APP_BACK_END_HOST_DEV}/api/business/product/flap_details`
          : `${process.env.REACT_APP_BACK_END_HOST}/api/business/product/flap_details`,
        { ...data }
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetIndustriesAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.INDUSTRIES)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetCountriesAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.COUNTRIES)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const UserInvoiceLoginAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.INVOICE_LOGIN_URL, { ...data })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const UserInvoiceVerificationAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.INVOICE_VERIFICATION_URL, { ...data })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const UpdateBusinessProfileAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.ME, { ...data })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const WindCountriesAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.WIND_COUNTRIES)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const WindBankBranchesAPI = (bankId) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.WIND_BANK_BRANCHES + `?bankId=${bankId}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const WindBankDetailsAPI = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.WIND_BANK_DETAILS, payload)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const WindCustomerAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_CUSTOMER)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const WindRequiredFieldsAPI = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.REQUIRE_FIELDS, payload)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetWindBeneficiaryAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_BENEFICIARY)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const ActiveWindBeneficiaryAPI = (wind_beneficiary_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(
        url.GET_BENEFICIARY + `?wind_beneficiary_id=${wind_beneficiary_id}`
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetAllWindBeneficiaryAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.ALL_BENEFICIARY)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
