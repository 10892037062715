import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { BackSVG } from "../../../App";
import "react-phone-input-2/lib/bootstrap.css";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  GetIndustriesAPI,
  UpdateBusinessProfileAPI,
} from "../../../services/auth";
import toast, { Toaster } from "react-hot-toast";
import { notificationMessageFunction } from "../../../services/notificationConst";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import "react-phone-input-2/lib/bootstrap.css";
import { GetMyProfileAPI } from "../../../services/invoice";

const SignupSchema = Yup.object().shape({
  company_name: Yup.string()
    .required("Business name is required")
    .min(2, "Business name must be at least 2 characters long")
    .max(50, "Business name must be less than 50 characters long"),
  industry: Yup.string().required("Business industry is required"),
  industry_type: Yup.string().required("Business industry type is required"),
  registration_number: Yup.string()
    .required("Registration number is required")
    .matches(
      /^[0-9a-zA-Z]+$/,
      "Registration number must contain only alphanumeric characters"
    ),
  website: Yup.string()
    .required("Website is required")
    .url("Please enter a valid URL"),
});

function BusinessInfoPage1() {
  const [industriesList, setIndustriesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const history = useHistory();

  const getMyProfile = () => {
    setIsLoading(true);
    GetMyProfileAPI()
      .then((res) => {
        setIsLoading(false);
        const data = res.data.data.data;
        setUserData(data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getMyProfile();
  }, []);

  const getIndustriesAssets = () => {
    GetIndustriesAPI()
      .then((res) => {
        if (res.data.data.industries) {
          setIndustriesList(res.data.data.industries);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getIndustriesAssets();
  }, []);

  const handleSubmit = (values) => {
    setIsLoading(true);
    const data = {
      ...values,
      email: userData.email.toLowerCase(),
      code: "",
    };

    UpdateBusinessProfileAPI(data)
      .then((res) => {
        setTimeout(() => {
          toast.success("Your details updated successfully");
        }, 100);
        localStorage.setItem("EMAIL", data.email);
        setIsLoading(false);
        history.push({
          pathname: `/invoice-bank-details`,
          state: { email: data.email },
        });
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage = err.data.error.message;
        toast.error(notificationMessageFunction(errorMessage));
      });
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-column justify-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <div style={{ background: "#F7F7F7" }}>
          <div className="mt-4 mb-3 flex ml-3" style={{ width: "90%" }}>
            <div
              className="cursor-pointer"
              style={{ width: "20%" }}
              onClick={() => {
                history.push(`/invoice-business-info`);
              }}
            >
              {BackSVG}
            </div>{" "}
            <div
              className="flex align-items-center justify-content-center"
              style={{ width: "100%" }}
            >
              <span className="font-size-18 font-weight-600">
                Business Info
              </span>
            </div>
          </div>
          <SimpleBar forceVisible="y" style={{ maxHeight: "85vh" }}>
            <div
              className="mt-2"
              style={{
                minHeight: "80vh",
                position: "relative",
                marginBottom: "40px",
              }}
            >
              <Formik
                initialValues={{
                  company_name: userData?.company_name || "",
                  industry: userData?.industry || "",
                  industry_type: userData?.industry_type || "",
                  industry_risk: userData?.industry_risk || "",
                  registration_number: userData?.registration_number || "",
                  website: userData?.website || "",
                }}
                validationSchema={SignupSchema}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-2 flex flex-col px-4 pt-2">
                      <label
                        htmlFor="email"
                        className="font-size-14 ml-1 mb-1 font-weight-500"
                      >
                        Business Name
                      </label>
                      <input
                        type="text"
                        value={values.company_name}
                        placeholder="Enter business name"
                        onChange={handleChange}
                        name="company_name"
                        className={`input-field font-size-14 ${
                          errors.company_name && touched.company_name
                            ? "error-border"
                            : ""
                        }`}
                      />
                      {errors.company_name && touched.company_name && (
                        <div className="text-left mt-1 ml-2 text-danger font-size-14">
                          {errors.company_name}
                        </div>
                      )}
                    </div>

                    <div className="mb-2 pt-2 flex flex-col px-4">
                      <label
                        htmlFor="email"
                        className="font-size-14 ml-1 mb-1 font-weight-500"
                      >
                        Business Industry
                      </label>
                      <Select
                        styles={{
                          menuList: (provided) => ({
                            ...provided,
                            maxHeight: "300px",
                            fontSize: "14px",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 10000,
                          }),
                        }}
                        className={`select-input font-weight-500 font-size-14 ${
                          errors.industry && touched.industry
                            ? "error-border"
                            : ""
                        }`}
                        placeholder="Select Industry"
                        classNamePrefix="select-input"
                        options={industriesList}
                        value={industriesList.find(
                          (c) => c.value === values.industry
                        )}
                        onChange={(val) => {
                          if (val) {
                            setFieldValue("industry", val.value);
                            setFieldValue("industry_risk", val.risk);
                          } else {
                            setFieldValue("industry_risk", "");
                            setFieldValue("industry", "");
                          }
                        }}
                        name="industry"
                      />
                      {errors.industry && touched.industry && (
                        <div className="text-left mt-1 ml-2 text-danger font-size-14">
                          {errors.industry}
                        </div>
                      )}
                    </div>

                    <div className="mb-2 pt-2 flex flex-col px-4">
                      <label
                        htmlFor="email"
                        className="font-size-14 ml-1 mb-1 font-weight-500"
                      >
                        Business Type
                      </label>
                      <Select
                        styles={{
                          menuList: (provided) => ({
                            ...provided,
                            maxHeight: "300px",
                            fontSize: "14px",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 10000,
                          }),
                        }}
                        className={`select-input font-weight-500 font-size-14 ${
                          errors.industry_type && touched.industry_type
                            ? "error-border"
                            : ""
                        }`}
                        placeholder="Select Industry Type"
                        classNamePrefix="select-input"
                        options={[
                          { label: "Physical Goods", value: "physical_goods" },
                          {
                            label: "Digital Goods",
                            value: "digital_goods_services",
                          },
                        ]}
                        value={[
                          { label: "Physical Goods", value: "physical_goods" },
                          {
                            label: "Digital Goods",
                            value: "digital_goods_services",
                          },
                        ].find((c) => c.value === values.industry_type)}
                        onChange={(val) => {
                          if (val) {
                            setFieldValue("industry_type", val.value);
                          } else {
                            setFieldValue("industry_type", "");
                          }
                        }}
                        name="industry_type"
                      />
                      {errors.industry_type && touched.industry_type && (
                        <div className="text-left mt-1 ml-2 text-danger font-size-14">
                          {errors.industry_type}
                        </div>
                      )}
                    </div>

                    <div className="mb-2 pt-2 flex flex-col px-4">
                      <label
                        htmlFor="registration_number"
                        className="font-size-14 ml-1 mb-1 font-weight-500"
                      >
                        Registration Number
                      </label>{" "}
                      <input
                        type="text"
                        value={values.registration_number}
                        placeholder="Enter registration number"
                        onChange={handleChange}
                        name="registration_number"
                        className={`input-field font-size-14 ${
                          errors.registration_number &&
                          touched.registration_number
                            ? "error-border"
                            : ""
                        }`}
                      />
                      {errors.registration_number &&
                        touched.registration_number && (
                          <div className="text-left mt-1 ml-2 text-danger font-size-14">
                            {errors.registration_number}
                          </div>
                        )}
                    </div>

                    <div className="mb-2 pt-2 flex flex-col px-4">
                      <label
                        htmlFor="email"
                        className="font-size-14 ml-1 mb-1 font-weight-500"
                      >
                        Website
                      </label>{" "}
                      <input
                        type="text"
                        value={values.website}
                        placeholder="Enter website"
                        onChange={handleChange}
                        name="website"
                        className={`input-field font-size-14 ${
                          errors.website && touched.website
                            ? "error-border"
                            : ""
                        }`}
                      />
                      {errors.website && touched.website && (
                        <div className="text-left mt-1 ml-2 text-danger font-size-14">
                          {errors.website}
                        </div>
                      )}
                    </div>

                    <div
                      className="flex justify-center p-4"
                      style={{
                        width: "100%",
                        position: "fixed",
                        bottom: "5px",
                      }}
                    >
                      <button
                        id="login-button"
                        type="submit"
                        className="button-style"
                      >
                        <div className="font-size-14">Continue</div>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </SimpleBar>
        </div>
      )}
      <Toaster position="bottom-center" reverseOrder={false} />
    </React.Fragment>
  );
}

export default BusinessInfoPage1;
