import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "./Property.css";
import "react-phone-input-2/lib/bootstrap.css";
import {
  FaPhoneAlt,
  FaClock,
  FaQuestion,
  FaArrowLeft,
  FaRegClock,
} from "react-icons/fa";

function Communication({
  handleSubmit,
  setStep,
  defaultValues,
  email,
  shakeIt,
}) {
  const communicationSubmit = (values) => {
    const data = {
      preferredMethod: values.preferredMethod,
      startTime: values.startTime,
      endTime: values.endTime,
      timeframe: values.timeframe,
      additionalDetails: values.additionalDetails,
      email,
    };
    handleSubmit(data);
    if (values.timeframe === "immediate") {
      setStep(2);
    } else {
      setStep(4);
    }
  };

  return (
    <Formik
      initialValues={{
        preferredMethod:
          defaultValues && defaultValues.preferredMethod
            ? defaultValues.preferredMethod
            : "",
        startTime:
          defaultValues && defaultValues.startTime
            ? defaultValues.startTime
            : "",
        endTime:
          defaultValues && defaultValues.endTime ? defaultValues.endTime : "",
        timeframe:
          defaultValues && defaultValues.timeframe
            ? defaultValues.timeframe
            : "",
        additionalDetails:
          defaultValues && defaultValues.additionalDetails
            ? defaultValues.additionalDetails
            : "",
      }}
      validationSchema={Yup.object({
        preferredMethod: Yup.string().required(
          "Preferred communication method is required"
        ),
        startTime: Yup.string()
          .required("Start time is required")
          .test(
            "valid-start-time",
            "Start time must be before end time",
            function (value) {
              const { endTime } = this.parent;
              return endTime ? value < endTime : true;
            }
          ),
        endTime: Yup.string()
          .required("End time is required")
          .test(
            "valid-end-time",
            "End time must be after start time",
            function (value) {
              const { startTime } = this.parent;
              return startTime ? value > startTime : true;
            }
          ),
      })}
      enableReinitialize
      onSubmit={communicationSubmit}
    >
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        handleSubmit,
      }) => (
        <Form
          className={`search-box glass-effect py-4 ${
            shakeIt && "tilt-shaking"
          }`}
          onSubmit={handleSubmit}
        >
          <div className="cursor-pointer" onClick={() => setStep(0)}>
            <FaArrowLeft />
          </div>
          <span className="font-size-18 font-weight-500">
            Preferred Communication Method
          </span>

          <div className="search-field input-icon-wrapper">
            <FaPhoneAlt className="input-icon" />
            <select
              id="preferredMethod"
              name="preferredMethod"
              className="input-field-pr"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.preferredMethod}
            >
              <option value="" className="font-size-14 text-black">
                Preferred Contact
              </option>
              <option value="whatsapp" className="font-size-14 text-black">
                WhatsApp
              </option>
              <option value="line" className="font-size-14 text-black">
                Line
              </option>
              <option value="email" className="font-size-14 text-black">
                Email
              </option>
              <option value="phoneCall" className="font-size-14 text-black">
                Phone Call
              </option>
            </select>
          </div>
          {touched.preferredMethod && errors.preferredMethod ? (
            <div className="error-message">{errors.preferredMethod}</div>
          ) : null}

          <label
            htmlFor="startTime"
            className="font-size-14 text-white font-weight-500"
          >
            Best Time to Contact
          </label>
          <div className="time-range-container d-flex flex-row justify-content-between align-items-center">
            <div className="search-field input-icon-wrapper">
              <FaRegClock className="input-icon" />
              <input
                id="startTime"
                name="startTime"
                type="time"
                className="input-field-pr"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.startTime}
                placeholder="Start Time"
              />
            </div>

            <span>To</span>
            <div className="search-field input-icon-wrapper">
              <FaRegClock className="input-icon" />
              <input
                id="endTime"
                name="endTime"
                type="time"
                className="input-field-pr"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.endTime}
                placeholder="End Time"
              />
            </div>
          </div>
          {touched.startTime && errors.startTime ? (
            <div className="error-message">{errors.startTime}</div>
          ) : null}

          {touched.endTime && errors.endTime ? (
            <div className="error-message">{errors.endTime}</div>
          ) : null}

          <div className="search-field input-icon-wrapper">
            <FaClock className="input-icon" />
            <select
              id="timeframe"
              name="timeframe"
              className="input-field-pr"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.timeframe}
            >
              <option value="" className="font-size-14 text-black">
                Timeframe for Purchase
              </option>
              <option value="immediate" className="font-size-14 text-black">
                Immediate
              </option>
              <option value="3months" className="font-size-14 text-black">
                Within 3 months
              </option>
              <option value="3-6months" className="font-size-14 text-black">
                3-6 months
              </option>
              <option value="6-12months" className="font-size-14 text-black">
                6-12 months
              </option>
            </select>
          </div>
          {touched.timeframe && errors.timeframe ? (
            <div className="error-message">{errors.timeframe}</div>
          ) : null}

          <div className="search-field input-icon-wrapper">
            <FaQuestion className="input-icon" />
            <input
              id="additionalDetails"
              name="additionalDetails"
              type="text"
              className="input-field-pr"
              placeholder="Enter any additional details or questions..."
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.additionalDetails}
              autoComplete="off"
            />
          </div>
          {touched.additionalDetails && errors.additionalDetails ? (
            <div className="error-message">{errors.additionalDetails}</div>
          ) : null}

          <button type="submit" className="search-button">
            Continue
          </button>
        </Form>
      )}
    </Formik>
  );
}

export default Communication;
