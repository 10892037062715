import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { GetInvoiceDetailsAPI } from "../../services/invoice";
import copy from "copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { BackSVG, CopySVG } from "../../App";
import BankLogo from "../../images/Mask group.svg";
import { RiFileCopyLine } from "react-icons/ri";

function BankPayment() {
  const { invoice_code } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [invoiceData, setInvoiceData] = useState(null);
  const history = useHistory();

  const getInvoiceDetails = () => {
    setIsLoading(true);
    GetInvoiceDetailsAPI(invoice_code)
      .then((res) => {
        setIsLoading(false);
        setInvoiceData({
          ...res.data.data.data,
          ...res.data.data.industryType,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMsg = err.data?.error?.message;
        setErrorMessage(errorMsg);
      });
  };

  useEffect(() => {
    if (invoice_code) {
      getInvoiceDetails();
    } else {
      setErrorMessage("Invoice code is required");
    }
  }, []);

  const amountInUSDC =
    invoiceData?.pay_fee === "you_pay"
      ? Number(invoiceData?.amount).toFixed(6)
      : Number(invoiceData?.final_amount).toFixed(6);

  return (
    <div className="">
      {isLoading ? (
        <div
          className="flex flex-column justify-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <div style={{ background: "#F7F7F7" }}>
          {errorMessage && <span>{errorMessage}</span>}
          {invoiceData && Object.keys(invoiceData).length > 0 && (
            <SimpleBar
              forceVisible="y"
              className="p-4"
              style={{ maxHeight: "91vh" }}
            >
              <div className="mb-3 flex" style={{ width: "90%" }}>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  {BackSVG}
                </div>{" "}
                <div
                  className="flex align-items-center justify-content-center"
                  style={{ width: "100%" }}
                >
                  <label className="font-size-18 label-color font-weight-600">
                    Payment Detail
                  </label>
                </div>
              </div>
              <div className="d-flex flex-col">
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex flex-col">
                    <span className="font-size-14 font-weight-500">
                      Payment Amount
                    </span>
                    <span className="font-size-24 font-weight-600 mt-4">
                      $ {amountInUSDC}
                    </span>
                  </div>
                  <img src={BankLogo} alt="bank-logo" width={60} height={60} />
                </div>
                <div className="mt-3">
                  <label className="font-size-14 label-color">
                    Payment Reference
                  </label>
                  <div className="mb-1 flex flex-row items-center">
                    <input
                      type="text"
                      value={
                        invoiceData.bank_details.sourceDepositInstructions
                          .referenceNumber
                      }
                      placeholder="Enter email address"
                      name="email"
                      className={`input-field font-size-14 flex-1`}
                      style={{
                        paddingRight: "36px",
                        border: "1px dashed #C0C0C0",
                      }}
                      disabled
                    />
                    <div
                      onClick={() => {
                        copy(
                          invoiceData.bank_details.sourceDepositInstructions
                            .referenceNumber
                        );
                        toast.success("Reference number copied successfully", {
                          style: { fontSize: "12px" },
                        });
                      }}
                      style={{
                        position: "absolute",
                        right: "25px",
                        fontSize: "24px",
                      }}
                    >
                      <RiFileCopyLine className="ml-2 cursor-pointer" />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex flex-col mt-4">
                    <span className="font-size-16 font-weight-600">
                      Bank Details
                    </span>

                    <CommonBankDetails
                      label={"Bank Name"}
                      value={
                        invoiceData?.bank_details?.sourceDepositInstructions
                          ?.bankName
                      }
                    />
                    <CommonBankDetails
                      label={"Beneficiary Name"}
                      value={
                        invoiceData?.bank_details?.sourceDepositInstructions
                          ?.bankBeneficiaryName
                      }
                    />
                    <CommonBankDetails
                      label={"Account Number"}
                      value={
                        invoiceData?.bank_details?.sourceDepositInstructions
                          ?.bankAccountNumber
                      }
                    />
                    <CommonBankDetails
                      label={"Bank Address"}
                      value={
                        invoiceData?.bank_details?.sourceDepositInstructions
                          ?.bankAddress
                      }
                    />
                    <CommonBankDetails
                      label={"Bank Routing Number"}
                      value={
                        invoiceData?.bank_details?.sourceDepositInstructions
                          ?.bankRoutingNumber
                      }
                    />
                    <CommonBankDetails
                      label={"Beneficiary Address"}
                      value={
                        invoiceData?.bank_details?.sourceDepositInstructions
                          ?.beneficiaryAddress
                      }
                    />
                    <CommonBankDetails
                      label={"Receiving Bank Address"}
                      value={
                        invoiceData?.bank_details?.sourceDepositInstructions
                          ?.receivingBankAddress
                      }
                    />
                    <CommonBankDetails
                      label={"Reference Number"}
                      value={
                        invoiceData?.bank_details?.sourceDepositInstructions
                          ?.referenceNumber
                      }
                    />
                  </div>
                </div>
              </div>
            </SimpleBar>
          )}
          <div
            className="flex justify-center p-4 button-div"
            style={{
              position: "absolute",
              bottom: "5px",
            }}
            onClick={() => {
              history.push(`/bank-paid/${invoice_code}`);
            }}
          >
            <button id="login-button" type="submit" className="button-style">
              <div className="font-size-14">I have sent it</div>
            </button>
          </div>
        </div>
      )}

      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
}

export default BankPayment;

const CommonBankDetails = ({ label, value }) => {
  return (
    <div className="d-flex flex-col mt-4">
      <label className="font-size-12 label-color" style={{ color: "#8e8eae" }}>
        {label}
      </label>
      <div className="d-flex flex-row  justify-content-between">
        <span className="font-size-16 font-weight-500 text-black">{value}</span>
        <div
          className="d-flex flex-row cursor-pointer align-items-center"
          style={{ height: "16px" }}
          onClick={() => {
            copy(value);
            toast.success(`${label} copied successfully`, {
              style: { fontSize: "12px" },
            });
          }}
        >
          {CopySVG}
          <span className="ml-2 font-size-12 font-weight-400">Copy</span>
        </div>
      </div>
    </div>
  );
};
