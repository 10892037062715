import React, { useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import "../login.css";
import { notificationMessageFunction } from "../../../services/notificationConst";
import toast, { Toaster } from "react-hot-toast";
import { UserInvoiceLoginAPI } from "../../../services/auth";
import { useHistory } from "react-router-dom";
import { preAuthenticate } from "thirdweb/wallets/in-app";
import { client } from "../client";

function LoginPage() {
  const history = useHistory();

  //   const result = await authenticate({
  //   client,
  //   strategy: "email",
  //   email: "example@example.org",
  //   verificationCode: "123456",
  // });

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    const data = {
      email: values.email.toLowerCase(),
    };
    setIsLoading(true);
    await preAuthenticate({
      client,
      strategy: "email",
      email: values.email.toLowerCase(),
    })
      .then((res) => {
        UserInvoiceLoginAPI(data)
          .then((res) => {
            localStorage.setItem("email", values.email);

            setIsLoading(false);
            history.push({
              pathname: "/invoice-verification",
              state: { email: values.email },
            });
          })
          .catch((err) => {
            const errorMessage = err?.data?.error?.message;
            toast.error(notificationMessageFunction(errorMessage), {
              style: { fontSize: "12px" },
            });
          });
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage = err.data.error.message;
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: "12px" },
        });
      });
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-column justify-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <div style={{ background: "#F7F7F7" }}>
          <div className="mt-5 mb-3">
            <span
              className="text-left page-title font-size-48 mt-4 p-4 flex align-items-center justify-center"
              style={{ marginTop: "20%", marginBottom: "20%" }}
            >
              1buy.io
            </span>
            <div className="mt-2">
              <label
                htmlFor="email"
                className="font-size-16 ml-1 mb-1 px-4 font-weight-500"
              >
                Sign In
              </label>
              <Formik
                initialValues={{ email: "" }}
                enableReinitialize
                validationSchema={SignupSchema}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-1 flex flex-col px-4">
                      <input
                        type="text"
                        value={values.email}
                        placeholder="Enter email address"
                        onChange={handleChange}
                        name="email"
                        className={`input-field font-size-14 ${
                          errors.email && touched.email ? "error-border" : ""
                        }`}
                      />
                      {errors.email && touched.email && (
                        <div className="text-left mt-1 ml-2 text-danger font-size-14">
                          {errors.email}
                        </div>
                      )}
                    </div>

                    <div
                      className="flex justify-center p-4 button-div"
                      style={{
                        position: "fixed",
                        bottom: "5px",
                      }}
                    >
                      <button
                        id="login-button"
                        type="submit"
                        className="button-style"
                      >
                        <div className="font-size-14">Sign In</div>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}

      <Toaster position="bottom-center" reverseOrder={false} />
    </React.Fragment>
  );
}

export default LoginPage;
