import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BackSVG } from "../../App";

function SidebarDrawer() {
  let params = window.location.search;
  const history = useHistory();

  return (
    <div style={{ background: "#F7F7F7" }}>
      <div className="mt-4 mb-3 flex ml-4" style={{ width: "90%" }}>
        <div
          className="cursor-pointer"
          onClick={() => {
            history.push(`/invoice-widget`);
          }}
        >
          {BackSVG}
        </div>{" "}
        <div
          className="flex align-items-center justify-content-center"
          style={{ width: "100%" }}
        >
          <span className="font-size-18 font-weight-600">Menu</span>
        </div>
      </div>
      <div className="mr-3 ml-3 py-3">
        <Link to={`/invoices/${params}`}>
          <p className="flex align-items-center cursor-pointer">
            <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
              Personal Information
            </label>
            <AiOutlineRight
              style={{ position: "absolute", right: "10px", color: "black" }}
            />
          </p>
        </Link>
      </div>
      <hr className="mr-3 ml-3" />
      <div className="mr-3 ml-3 py-3">
        <Link to={`/invoice-wallet/${params}`}>
          <p className="flex align-items-center cursor-pointer">
            <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
              Connect Wallet
            </label>
            <AiOutlineRight
              style={{ position: "absolute", right: "10px", color: "black" }}
            />
          </p>
        </Link>
      </div>
      <hr className="mr-3 ml-3" />
      <div className="mr-3 ml-3 py-3">
        <Link to={`/invoice-business-info/${params}`}>
          <p className="flex align-items-center cursor-pointer">
            <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
              Business Details
            </label>
            <AiOutlineRight
              style={{ position: "absolute", right: "10px", color: "black" }}
            />
          </p>
        </Link>
      </div>
      <hr className="mr-3 ml-3" />
      <div className="mr-3 ml-3 py-3">
        <p className="flex align-items-center cursor-pointer">
          <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
            Connect Accounting Software (Coming Soon)
          </label>
          <AiOutlineRight
            style={{ position: "absolute", right: "10px", color: "black" }}
          />
        </p>
      </div>
      <hr className="mr-3 ml-3" />
      <div className="mr-3 ml-3 py-3">
        <Link to={`/invoices/${params}`}>
          <p className="flex align-items-center cursor-pointer">
            <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
              Invoices
            </label>
            <AiOutlineRight
              style={{ position: "absolute", right: "10px", color: "black" }}
            />
          </p>
        </Link>
      </div>
      <hr className="mr-3 ml-3" />
      <div className="mr-3 ml-3 py-3">
        <Link to={`/invoice-bank-details/${params}`}>
          <p className="flex align-items-center cursor-pointer">
            <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
              Connect Bank Account
            </label>
            <AiOutlineRight
              style={{ position: "absolute", right: "10px", color: "black" }}
            />
          </p>
        </Link>
      </div>
      <hr className="mr-3 ml-3" />
      <div className="mr-3 ml-3 py-3">
        <Link to={`/invoice-bank-accounts/${params}`}>
          <p className="flex align-items-center cursor-pointer">
            <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
              Bank Accounts
            </label>
            <AiOutlineRight
              style={{ position: "absolute", right: "10px", color: "black" }}
            />
          </p>
        </Link>
      </div>
      <hr className="mr-3 ml-3" />

      <div className="mr-3 ml-3 py-3">
        <p className="flex align-items-center cursor-pointer">
          <label className="font-size-16 ml-2 font-weight-500 cursor-pointer">
            Saved Products (Coming soon)
          </label>
          <AiOutlineRight
            style={{ position: "absolute", right: "10px", color: "black" }}
          />
        </p>
      </div>
      <hr className="mr-3 ml-3" />
    </div>
  );
}

export default SidebarDrawer;
