import React, { useEffect, useState } from "react";
import {
  getInvoiceDepositAddressAPI,
  getInvoiceSmartContractAPI,
  GetMyProfileAPI,
  InvoiceTransactionsAPI,
  setInvoiceDepositAddressAPI,
} from "../../services/invoice";
import toast, { Toaster } from "react-hot-toast";
import { notificationMessageFunction } from "../../services/notificationConst";
import { useHistory } from "react-router-dom";
import { useActiveAccount } from "thirdweb/react";
import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { SentSVG } from "../../App";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

function InvoiceWidget() {
  const account = useActiveAccount();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [contractDetail, setContractDetail] = useState(null);
  const [invoiceDepositWallet, setInvoiceDepositWallet] = useState("");
  const [chartData, setChartData] = useState([0, 0]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [userData, setUserData] = useState({});

  const getContractDetail = () => {
    setIsLoading(true);
    getInvoiceSmartContractAPI()
      .then((res) => {
        const data = res.data.data.data;
        setContractDetail(data);
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getContractDetail();
  }, []);

  const getMyProfile = () => {
    setIsLoading(true);
    GetMyProfileAPI()
      .then((res) => {
        setIsLoading(false);
        const data = res.data.data.data;

        if (
          !(
            res.data.data.data.phone_number &&
            res.data.data.data.company_name &&
            res.data.data.data.industry_type &&
            res.data.data.data.industry
          )
        ) {
          history.push(`/invoice-business-info`);
        }

        if (
          res.data.data.data.invoice_deposit_wallet === undefined ||
          res.data.data.data.invoice_deposit_wallet === ""
        ) {
          history.push(`/invoice-wallet`);
        }

        setUserData(data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getMyProfile();
    getContractDetail();
  }, []);

  const getDepositAddress = () => {
    setIsLoading(true);
    getInvoiceDepositAddressAPI()
      .then((res) => {
        setIsLoading(false);
        setInvoiceDepositWallet(res.data.data.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDepositAddress();
  }, []);

  const handleSetDepositWallet = (walletAddress) => {
    setInvoiceDepositAddressAPI({
      invoice_deposit_wallet: walletAddress,
    })
      .then((res) => {
        setInvoiceDepositWallet(walletAddress);
        // getDepositAddress();
      })
      .catch((err) => {
        const errorMessage = err.data.error.message;
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: "12px" },
        });
      });
  };

  const getTransactions = () => {
    setIsLoading(true);
    InvoiceTransactionsAPI()
      .then((res) => {
        setIsLoading(false);
        setChartData([res.data.data.data.paid, res.data.data.data.unpaid]);
        setInvoiceList(res.data.data.data.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const data = {
    labels: ["Paid", "Unpaid"],
    datasets: [
      {
        label: "# Invoices",
        data: chartData,
        backgroundColor: ["rgba(54, 162, 235, 0.2)", "rgba(255, 206, 86, 0.2)"],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    if (
      account &&
      account.address &&
      (!invoiceDepositWallet || account.address !== invoiceDepositWallet)
    ) {
      handleSetDepositWallet(account.address);
    }
  }, [account, account?.address, invoiceDepositWallet]);

  useEffect(() => {
    if (
      contractDetail &&
      contractDetail.event !== "deployed" &&
      contractDetail.event !== "deployment_failed"
    ) {
      const intervalId = setInterval(() => {
        getContractDetail();
      }, 10000);

      return () => clearInterval(intervalId);
    }
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="flex flex-column justify-center"
          style={{ height: "100vh", alignItems: "center" }}
        >
          <div className="loader-progress"></div>
        </div>
      ) : (
        <>
          <SimpleBar
            forceVisible="y"
            style={{ maxHeight: "100vh" }}
            className="p-4"
          >
            {" "}
            <div className="mt-5">
              <span className="text-left page-title mt-4 px-4 pt-4">
                Start Creating Invoices
              </span>
            </div>
            <div className="mb-1 flex flex-col">
              <div
                className="p-4"
                style={{
                  filter:
                    contractDetail && contractDetail.event === "deployed"
                      ? ""
                      : `blur(5px)`,
                }}
              >
                <label className="font-size-14">Create 1buy.io Invoice</label>
                <div className="d-flex flex-row mt-2">
                  <button
                    onClick={() => history.push("/simple-invoice")}
                    className="button-style font-size-14 mr-2"
                    disabled={
                      contractDetail && contractDetail.event === "deployed"
                        ? false
                        : true
                    }
                  >
                    Simple Invoice
                  </button>
                  <button
                    onClick={() => history.push("/full-invoice")}
                    className="button-style font-size-14 mL-2"
                    disabled={
                      contractDetail && contractDetail.event === "deployed"
                        ? false
                        : true
                    }
                  >
                    Full Invoice
                  </button>
                </div>
              </div>
            </div>
            {invoiceList && invoiceList.length > 0 && (
              <div className="chart-container d-flex justify-content-center align-items-center">
                <div
                  className="chart-container d-flex justify-content-center align-items-center"
                  style={{ width: "300px", height: "300px" }}
                >
                  <Pie data={data} />
                </div>
              </div>
            )}
            <div className="d-flex justify-content-between mx-4 mt-3">
              <span className="font-size-16 font-weight-500">Invoices</span>
              <span
                className="font-size-16 cursor-pointer font-weight-500"
                onClick={() => {
                  history.push("/invoices");
                }}
              >
                All
              </span>
            </div>
            {invoiceList && invoiceList.length > 0 ? (
              <div className="mx-4 mt-3">
                {invoiceList.map((invoice) => (
                  <Link
                    to={`/invoice-detail?invoice_code=${invoice.short_code}`}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-col">
                        <span className="font-size-14 font-weight-500">
                          {invoice.title}
                        </span>
                        <div className="d-flex flex-row mt-2">
                          <span className="font-size-12 font-weight-300">
                            $ {invoice.amount}
                          </span>
                          <span className="mt-1 ml-2">{SentSVG}</span>
                        </div>
                      </div>
                      <div className="button-group">
                        {!invoice.is_paid ? (
                          <button
                            className={`font-size-14 font-weight-500`}
                            style={{
                              background: "#FFC694",
                              border: "0px",
                              color: "#000",
                            }}
                          >
                            Pending
                          </button>
                        ) : (
                          <span
                            className="ml-3 px-2 py-1 font-size-14 font-weight-500 rounded-full"
                            style={{ background: "#BEFF7D" }}
                          >
                            Complete
                          </span>
                        )}
                      </div>
                    </div>
                    <hr className="my-2" />
                  </Link>
                ))}
              </div>
            ) : (
              <div
                className="d-flex font-size-18 font-weight-500 align-items-center justify-content-center"
                style={{ height: "80vh", width: "100%" }}
              >
                No invoice found
              </div>
            )}{" "}
          </SimpleBar>
        </>
      )}
      <Toaster position="bottom-center" reverseOrder={false} />
    </React.Fragment>
  );
}

export default InvoiceWidget;
